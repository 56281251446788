import React, { useState } from "react";
import { QuantitySelector, useMachineCartChange, useMachineCartItemUpdate } from "ui";

function App() {
    const [key, setKey] = useState(0);
    const state = window.app.preloadState.quantitySelector;
    const update = useMachineCartItemUpdate(state.code);

    useMachineCartChange(cart => {
        const inCartQuantity = cart.lineItems.find(m => m.code == state.code)?.quantity;
        if (state.initialValue != (inCartQuantity ?? 0)) {
            state.initialValue = inCartQuantity ?? 0;
            setKey(k => k + 1);
        }
    });

    const onChange = async (quantity: number) => {
        await update(quantity);
    }

    const onRemove = async (f: () => void) => { await onChange(0); f() }
    const translations = (window as any).app.preloadState.translation;

    return <QuantitySelector key={key}
        buttonLabel={translations["machinePdp.buy"]}
        buttonLabelAdded={translations["machinePdp.added"]}
        {...state}
        onChange={onChange}
        onRemove={onRemove}
        requestQuotationByEmail={""}
        contactSupportLink={""}
        showRemanufacturePopUp={undefined}
        forceMinValue={false}
        showLogin={false}
        showNotAuthorized={false}
        showRequestQuotationByEmail={false}
        showContactSupport={false}
    />
}

export default App;
